<template>
    <div>
        <a-spin size="large" :spinning="spinning">
            <a-page-header title="企业数据同步管理" @back="goBack" />
        </a-spin>
        <div style="min-height: 70vh;border: 1px solid #cccccc;padding: 20px;">
            <a-alert message="每年12月31日，同步企业数据将被清空。" banner />
            <div style="display: flex;align-items: center;margin-top: 15px;">
                <span>
                    统一社会信用代码：
                    <a-input style="width: 200px" placeholder="请输入统一社会信用代码" v-model="usCode" />
                </span>
                <a-button type="primary" @click="searchList" style="margin-left: 10px;">
                    <a-icon type="search" />
                    查询
                </a-button>
                <a-button class="m-10" style="margin-left: 10px;" @click="resetData"> 重置 </a-button>
            </div>
            <div>
                <a-button type="primary" style="margin-top: 15px" @click="dialogVisib = true">
                    <a-icon type="plus" />
                    新增同步企业
                </a-button>
                <a-button type="primary" style="margin-top: 15px;margin-left: 10px;" @click="getErrorUsCodeList">
                    批量新增同步企业
                </a-button>
                <a-button :loading="loading" type="primary" style="margin-top: 15px;margin-left: 10px;"
                    @click="updateInfos">
                    <a-icon type="redo" />
                    全部手动同步
                </a-button>
            </div>
            <div style="margin-top: 10px">
                <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="table.data"
                    :pagination="pagination" @change="pageChange" tableLayout="fixed">
                    <span slot="action" slot-scope="text, record">
                        <a-popconfirm placement="topRight" ok-text="确定"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" cancel-text="取消"
                            @confirm="putFollowBusinessAccomplish(text.state, text.id)">
                            <template slot="title">
                                <p>确定要{{ text.state == '0' ? '禁用' : '启用' }}吗？</p>
                            </template>
                            <a-button type="link" @click="visible = true; id = text.id">{{ text.state == '0' ? '禁用' :
                                '启用'
                                }}</a-button>
                        </a-popconfirm>

                        <a-button v-if="text.state == '0'" type="link" @click="updateInfo(text.id)">手动同步</a-button>
                    </span>
                </a-table>
            </div>
            <a-modal v-model="dialogVisib" :title="title" :footer="null" @cancel="handleCancel" style=" margin: 0 auto">
                <span> <span style="color:red">*</span>
                    统一社会信用代码：
                    <a-input @input="(e) => (addcode = validAllowCharNum(e))" style="width: 200px"
                        placeholder="请输入统一社会信用代码" v-model="addcode" />
                </span>
                <template>
                    <div style="display: flex;justify-content: end;margin-top: 10px;">
                        <a-button @click="handleCancel" style="margin-right: 10px;">
                            取消
                        </a-button>
                        <a-popconfirm placement="topRight" ok-text="确定"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" cancel-text="取消"
                            @confirm="addBinding" :disabled="disabled">
                            <template slot="title">
                                <p style="width: 351px;">点击确定后，若此企业存在，系统将扣除企业户名额并自动同步此企业数据。确定要新增吗？</p>
                            </template>
                            <a-button type="primary" @click="isaddcode">
                                确认
                            </a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </a-modal>
        </div>
    </div>
</template>
<script>
import * as api from '@/api/enterprise/enterprise.js'
export default {
    data() {
        return {
            dialogVisib: false,
            dialogVisib3: false,
            title: '新增同步企业',
            spinning: false,
            enterpriseInfo: {},
            dataInfo: {},
            loading: false,
            addcode: '',
            table: {
                columns: [
                    {
                        title: '统一社会信用代码',
                        dataIndex: 'us_code',
                        key: 'us_code'
                    },
                    {
                        title: '最后修改时间',
                        dataIndex: 'update_at',
                        key: 'update_at'
                    },
                    {
                        title: '最后修改人',
                        dataIndex: 'update_name',
                        key: 'update_name'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'create_at',
                        key: 'create_at'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'create_name',
                        key: 'create_name',
                        width: '200px'
                    },
                    {
                        title: '状态',
                        dataIndex: 'state',
                        key: 'state',
                        width: '200px',
                        customRender: (text, index) => {
                            switch (text) {
                                case 0:
                                    return "启用";
                                case 1:
                                    return "禁用";
                                default:
                                    break;
                            }
                        }

                    },
                    {
                        title: '操作',
                        key: 'action',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                data: [],
            },
            usCode: '',
            pagination: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            disabled: false
        }
    },
    methods: {
        // 返回
        goBack() {
            this.$router.go(-1)
        },
        pageChange(pageOption) {
            this.pagination.current = pageOption.current
            this.pagination.pageSize = pageOption.pageSize
            this.getEnterpriseName()
        },
        searchList() {
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getEnterpriseName()
        },
        async getEnterpriseName() {
            let res = await api.getBindingList(
                this.usCode,
                this.pagination.current,
                this.pagination.pageSize);
            console.log(res, '=====================');
            if (res.data.code == '200') {
                this.table.data = res.data.data.list
                this.pagination.total = res.data.data.total

            } else {
                this.$message.error(res.data.msg)
            }
        },
        resetData() {
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.usCode = ''
            this.getEnterpriseName()
        },
        async putFollowBusinessAccomplish(state, id,) {
            let res = await api.updateBindingState({ state: state == '0' ? 1 : 0, id: id })
            console.log(res);
            if (res.data.code === '200') {
                this.$message.success('操作成功')
            } else {
                this.$message.error(res.data.msg)
            }
            this.getEnterpriseName()
        },
        async updateInfo(id,) {
            let res = await api.updateInfo({ id: id })
            console.log(res);
            if (res.data.code === '200') {
                this.$message.success('操作成功')
            } else {
                this.$message.error(res.data.msg)
            }
            this.getEnterpriseName()
        },
        async updateInfos(id,) {
            this.loading = true
            let res = await api.updateInfos({ id: id })
            console.log(res);
            if (res.data.code === '200') {
                this.$message.success('操作成功')
            } else {
                this.$message.error(res.data.msg)
            }
            this.loading = false
            this.getEnterpriseName()
        },
        async addBinding(id) {
            if (!this.addcode) {
                return
            }
            let res = await api.addBinding({ us_code: this.addcode.toUpperCase() })
            console.log(res, '=========');
            if (res.data.code === '200') {
                this.$message.success('操作成功')
                this.handleCancel()
            } else {
                this.$message.error(res.data.msg)
            }
            this.getEnterpriseName()
        },
        handleCancel() {
            console.log('=============');
            this.dialogVisib = false;
            this.dialogVisib3 = false;
            this.fileObj = {}
            this.fileName = ''
            this.addcode = ''
        },
        checkFileType(file) {
            if (
                file.type === 'application/vnd.ms-excel' ||
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                return true
            }
            this.$message.error('只支持扩展名：xls、xlsx格式文件')
            return false
        },
        async importUsCode() {
            if (this.fileName == '') {
                this.$message.error('请先上传文件')
                return
            }
            console.log(this.fileObj);
            const form = new FormData()
            form.append('file', this.fileObj.file)
            let res = await api.importUsCode(form)
            console.log(res);
            if (res.data.code === '200') {
                this.$message.success('操作成功')
                this.handleCancel()
            } else {
                this.$message.error(res.data.msg)
            }
            this.getEnterpriseName()
        },
        async getErrorUsCodeList() {
            this.$router.push({
                path: '/enterprise/synchronizeAllEnterprises',
            })
        },
        isaddcode() {
            if (!this.addcode) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        },
    },
    created() {
        this.getEnterpriseName(this.fileObj)
    }
}
</script>
<style scoped lang="scss">
>>>.widtnModel {
    .ant-modal-content {
        width: 1000px;
        left: 50%;
        transform: translateX(-50%);
    }
}

>>>.ant-table-row .ant-btn {
    padding: revert;
}
</style>